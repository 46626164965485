<template>
  <section class="category-form-section">
  <v-container>
    <!-- Form to add/edit category -->
    <v-card>
      <v-card-title>Ajouter une Catégorie</v-card-title>
      <v-card-text>
    <v-form ref="form" v-model="valid" @submit.prevent="submitForm" class="category-form">
      
      <v-text-field 
        v-model="category.name" 
        label="Nom de catégorie" 
        outlined 
        required 
        :rules="nameRules"
      ></v-text-field>
      <v-row>
        <v-col>
      <VueEditor
        v-model="category.description"
        auto-grow
        outlined
        label="Description"
        
        placeholder="Description"
      >
      </VueEditor>
    </v-col>
    </v-row>
      <v-row v-if="category.name">
        <v-col cols="12" sm="6" md="4">
          <v-color-picker 
            v-model="category.color" 
            label="Couleur de catégorie" 
            outlined
            :rules="colorRules"
          ></v-color-picker>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <vue-upload-multiple-image
            :idUpload="imagesId"
            v-model="images"
            :data-images="images"
            name="images"
            drag-text="Please add an image!"
            browse-text="Browse image"
            primary-text="Primary image"
            mark-is-primary-text="Set default image"
            :min-image="1"
            :multiple="false"
            :loading="load"
            popup-text="Description default image"
            drop-text="Drag and drop"
            @upload-success="uploadImageSuccess"
            @before-remove="beforeRemove"
            @images-updated="handleImagesUpdated"
          ></vue-upload-multiple-image>
          <v-progress-linear
            v-if="showLoading && uploadPercentage < 100"
            :value="uploadPercentage"
            height="15"
            color="primary"
          >
            <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
          </v-progress-linear>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <vue-upload-multiple-image
            :idUpload="imagesIdBanner"
            v-model="bannerImage"
            :data-images="bannerImage"
            name="bannerImage"
            drag-text="Please add a banner image!"
            browse-text="Browse banner image"
            primary-text="Banner image"
            :min-image="1"
            :multiple="false"
            :loading="load"
            popup-text="Description banner image"
            drop-text="Drag and drop"
            @upload-success="uploadImageSuccessBanner"
            @before-remove="beforeRemoveBanner"
            @images-updated="handleBannerImageUpdated"
          ></vue-upload-multiple-image>
          <v-progress-linear
            v-if="showLoadingBanner && uploadPercentageBanner < 100"
            :value="uploadPercentageBanner"
            height="15"
            color="primary"
          >
            <strong>{{ Math.ceil(uploadPercentageBanner) }}%</strong>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn type="submit" color="info" :loading="loading" class="submit-btn">
            {{ isEditing ? 'Modifier' : 'Ajouter' }}
          </v-btn>
    </v-col>
    </v-row>
    </v-form>
  </v-card-text>
  </v-card>
    <v-divider class="my-4"></v-divider>

    <!-- Display data table of categories -->
    <v-card >
        <v-card-title>Liste des Catégories</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="categories"
      item-key="id"
      :search="search"
      :loading="loading"
      :no-data-text="noDataText"
    >
      <!-- <template v-slot:item.fileUrl="{ item }">
        <v-img :src="item.fileUrl" width="100px" height="100px" style="border-radius: 10px;margin:10px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"></v-img>
      </template> -->
      <template v-slot:item.fileUrl="{ item }">
          <v-img
            style="margin: 10px"
            :src="item.fileDBId ? getImageUrl(item.fileDBId) : item.fileUrl"
            width="65"
            height="65"
          />
          <v-divider></v-divider>
        </template>
        <!-- <template v-slot:item.fileBannerUrl="{ item }">
          <v-img
            style="margin: 10px"
            :src="item.fileBannerDBId ? getImageUrl(item.fileBannerDBId) : item.fileBannerUrl"
            width="65"
            height="65"
          />
          <v-divider></v-divider>
        </template>
         -->
      <template v-slot:item.color="{ item }">
        <v-avatar size="30" :style="{ backgroundColor: item.color }"></v-avatar>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon  @click="editCategory(item)" class="edit-icon">mdi-pencil</v-icon>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon  @click="confirmDeleteCategory(item.id)" class="delete-icon">mdi-delete</v-icon>
      </template>
    </v-data-table>
    </v-card>
     <!-- Confirmation Dialog -->
     <v-row justify="center">
      <v-dialog v-model="dialog" max-width="560">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer cette Catégorie?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
            @click="dialog = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text  @click="deleteCategory">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</section>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Constant from "@/utils/constants";
import store from "../../../store/image";
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import axios from 'axios';

export default {
  components: { 
    VueUploadMultipleImage,
    VueEditor,
   },
  data() {
    return {
      imageUrl: '',
      imageBannerUrl: '',
      categories: [],
      category: {
        id: null,
        name: '',
        description:'',
        color: '',
        fileDBId: null,
        // fileUrl: '',
        fileBannerDBId: null,
        // BannerUrl: '',
      },
      images: [],
      bannerImage:[],
      bannerImageUpload:[],
      imagesUpload:[],
      load: false,
      uploadPercentage: null,
      uploadPercentageBanner: null,
      fakePercentage: null,
      showLoading: false,
      showLoadingBanner: false,
      loading: false,
      valid: false,
      imagesId: "1",
      imagesIdBanner: "0",
      imageIdBanner: null,
      imageId: null,
      search: '',
      noDataText: 'No categories found',
      isEditing: false,
      headers: [
        { text: 'Image', value: 'fileUrl' },
        { text: 'Nom', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Couleur', value: 'color' },
        { text: 'Modifier', value: 'edit', sortable: false },
        { text: 'Supprimer', value: 'delete', sortable: false },
      ],
      dialog: false,
      deleteCategoryId: null,
      nameRules: [
      v => !!v || 'Nom de catégorie est requis',
    ],
    colorRules: [
      v => !!v || 'Couleur de catégorie est requise',
    ],
    };
  },
  created() {
    this.fetchCategories();
  },
  // async beforeRouteEnter(to, from, next) {
  //   try {
  //     const response = await fetch(Constant.QORANI + "library/category/getall");
  //     const categories = await response.json();

  //     next(vm => {
  //       vm.categories = categories; 
  //       if (categories.length > 0) {
  //         vm.category = categories[0]; 
  //         vm.imageUrl = vm.category.fileUrl;
  //         vm.imageBannerUrl = vm.category.fileBannerUrl;
  //       }
  //       console.log("imageUrl",vm.imageUrl);
  //       console.log("imageBannerUrl",vm.imageBannerUrl);


  //     });
  //   } catch (error) {
  //     console.error('Error fetching data in beforeRouteEnter:', error);
  //     next();
  //   }
  // },


  
  methods: {
    getImageUrl(fileDBId) {
      return `${Constant.QORANI}fileDB/files/${fileDBId}`;
    },
    getImageUrlBanner(fileBannerDBId) {
      return `${Constant.QORANI}fileDB/files/${fileBannerDBId}`;
    },
    async fetchCategories() {
      try {
        const response = await fetch(Constant.QORANI + "library/category/getall");
        this.categories = await response.json();
        console.log(this.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    async submitForm() {
      // Trigger validation
     this.$refs.form.validate();

          // Check if form is valid
          if (!this.valid) {
            console.log("La validation du formulaire a échoué.");
            return;
          }
      try {
        this.loading = true;
        if (this.category.id) {
          // Edit existing category
          const imageId = store.state.imageId;
      const imageIdBanner = store.state.imageIdBanner;
      
      this.category.fileDBId = imageId;
      this.category.fileBannerDBId = imageIdBanner;
      console.log(this.category.fileDBId ,
      this.category.fileBannerDBId );
      await fetch(Constant.QORANI + `library/category/edit/${this.category.id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.category),
          });
          console.log('Catégorie mise à jour avec succès !');
          console.log(this.category);

        } else {
          // Add new category
          const imageId = store.state.imageId;
          const imageIdBanner = store.state.imageIdBanner;

          this.category.fileDBId = imageId;
          this.category.fileBannerDBId = imageIdBanner;
          this.category.categoryId = this.$route.params.id;

          const response = await fetch(Constant.QORANI + "library/category/add", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.category),
          });
          const data = await response.json();
          //this.$refs.form.reset();
          console.log('Catégorie ajoutée avec succès !');
          this.category.id = data.id; // assuming API returns ID
          console.log(this.category);

        }
        //this.category = { id: null, name: '', description:'', color: '', fileId: null, fileUrl: '' }; // Reset form fields
         this.$refs.form.reset();
         this.fetchCategories(); // Refresh category list
      } catch (error) {
        console.error('Erreur lors de l\'envoi du formulaire :', error);
      } finally {
        this.loading = false;
      }
    },
    editCategory(category) {
      const imageId = store.state.imageId;
      const imageIdBanner = store.state.imageIdBanner;
      this.category.id = category.id;
      this.category.name = category.name;
      this.category.description = category.description;
      this.category.color = category.color;
      this.images = [{
      name: "",
      path: this.getImageUrl(category.fileDBId),
      highlight: 1,
      default: 1,
    }];
    
    this.bannerImage = [{
      name: "",
      path: this.getImageUrlBanner(category.fileBannerDBId),
      highlight: 1,
      default: 1,
    }];
      // this.category.fileDBId = imageId;
      // this.category.fileUrl = category.fileUrl;
      // this.category.fileBannerDBId = imageIdBanner;
      // this.category.BannerUrl = category.BannerUrl;
      this.isEditing = true;
      // Défilement automatique vers le formulaire
  this.$nextTick(() => {
    const formElement = this.$refs.form.$el; // Récupérer l'élément DOM du formulaire
    formElement.scrollIntoView({ behavior: 'smooth' }); // Faire défiler en douceur
  });
    },
    confirmDeleteCategory(categoryId) {
      this.dialog = true;
      this.deleteCategoryId = categoryId;
    },
    async deleteCategory() {
      try {
        await fetch(Constant.QORANI + `library/category/delete/${this.deleteCategoryId}`, {
          method: 'GET',
        });
        this.fetchCategories(); // Refresh category list
      } catch (error) {
        console.error(`Error deleting category ${this.deleteCategoryId}:`, error);
      } finally {
        this.dialog = false;
      }
    },
    handleImagesUpdated(images) {
      const uploadedImage = images[0];
      console.log("Image téléchargée :", uploadedImage);
    },
    
    async uploadImageSuccess(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccess", { formData, index, fileList });
        const imageId = store.state.imageId;
        console.log("Image id :", imageId);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    },
    beforeRemove(index, done, fileList) {
      this.showLoading = false;
      this.uploadPercentage = 0;
      const r = confirm("Remove image");
      if (r === true) {
        this.category.fileUrl = "";
        this.category.fileDBId = "";
        done();
      }
    },
  
    //////////////////////////////////////////////////////////////////////////
    handleBannerImageUpdated(bannerImage) {
      const uploadedImage = bannerImage[0];
      console.log("Image téléchargée :", uploadedImage);
     
    },
    async uploadImageSuccessBanner(formData, index, fileList) {
      try {
        await store.dispatch("uploadImageSuccessBanner", { formData, index, fileList });
        const imageIdBanner = store.state.imageIdBanner;
        console.log("Image idBanner :", imageIdBanner);
      } catch (error) {
        console.error("Failed to upload image:", error);
      }
    },
   
    beforeRemoveBanner(index, done, fileList) {
      this.showLoadingBanner = false;
      this.uploadPercentageBanner = 0;
      const r = confirm("Remove image");
      if (r === true) {
        this.category.bannerUrl = "";
        this.category.fileBannerDBId = "";
        done();
      }
    },
  
  }
};
</script>

<style scoped>
/* Base styles */
.category-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form styling */
.category-form {
  margin-bottom: 20px;
}

/* Input field styling */
.v-text-field, .v-color-picker {
  margin-bottom: 16px; /* Spacing between fields */
}

/* Button styling */
.v-btn {
  border-radius: 6px;

}

/* Divider styling */
.v-divider {
  margin: 24px 0; /* Increased spacing for dividers */
}

/* Data table styling */
.elevation-1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for data table */
  border-radius: 12px; /* Rounded corners */
}

.elevation-1 thead th {
  background-color: #f4f5f7; /* Light gray for headers */
  color: #333; /* Darker text for contrast */
  font-weight: 500; /* Slightly bold */
  border-bottom: 2px solid #e0e0e0; /* Subtle border */
}

/* Icon styling */
.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

/* Search field styling */
.v-text-field[v-model="search"] {
  max-width: 350px; /* Slightly wider search field */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .category-form-section {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-row {
    flex-direction: column; /* Stack elements vertically */
  }

  .v-col {
    width: 100%; /* Full width for each column on small screens */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables */
  }

  .v-btn {
    width: 100%; /* Full width buttons */
  }

  .v-card {
    margin-bottom: 15px; /* Adjusted margin for cards */
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 45%; /* Allow two items per row on larger screens */
    margin: 0 2.5%; /* Horizontal margin for spacing */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for desktop */
  }
}

/* Dialog styling */
.v-dialog .v-card {
  border-radius: 12px; /* Rounded corners for dialog */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for dialog */
}

.v-card-title {
  font-weight: 500; /* Bold title */
}
</style>